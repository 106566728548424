import { useMemo } from 'react'
import menu, { MenuItem, Menu } from '../data/menu'
import { MENU_MAX_PRIMARY_BRANDS, reparationsLinks } from '../data/menu.context'
import { repairModelPageBuilder } from '../templates/RepairModelPage.context'
import notEmpty from '../utils/notEmpty'
import useSaveMenuQuery from './useSaveMenuQuery'
//import useSaveModelsQuery from './useSaveQuoteModelQuery'
import { ProductTypeEnum, getProductByCode } from '../data/productTypes'
import capitalizeFirstLetterOfWords from '../utils/capitaliseFirstLetter'
import { repairOtherBrandPageBuilder } from '../templates/RepairOtherBrandPage.context'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

const useMenu = (): Menu => {
  const saveMenu = useSaveMenuQuery()
  //const saveModels = useSaveModelsQuery()
  return useMemo(() => {
    const brands = saveMenu?.brands?.filter(notEmpty) ?? []
    const models = saveMenu?.models?.filter(notEmpty) ?? []
    //const otherModels = saveModels.slice(0, 8)
    const primaryBrands = brands.slice(0, MENU_MAX_PRIMARY_BRANDS)
    const secondaryBrands = brands.slice(MENU_MAX_PRIMARY_BRANDS)

    const repairItem: MenuItem = {
      label: 'Réparation',
      position: 'left',
      url: reparationsLinks.all,
      nested: true,
      image: `${process.env.GATSBY_SITE_URL}images/reparation_smartphone.jpg`,
      items: [
        // mains
        ...primaryBrands.map((brand) => {
          const item: MenuItem = {
            label: `Réparation ${capitalizeFirstLetterOfWords(
              brand.name ?? ''
            )}`,
            url: repairOtherBrandPageBuilder(
              getProductByCode(ProductTypeEnum.SMA)?.urlCode ?? '',
              brand.name ?? ''
            ),
            type: 'models',
            other: {
              label: 'Autres modèles',
              url: repairOtherBrandPageBuilder(
                getProductByCode(ProductTypeEnum.SMA)?.urlCode ?? '',
                brand.name ?? ''
              ),
            },
            items: models
              .filter((x) => x.brandName === brand.name)
              .map((model) => ({
                label: capitalizeFirstLetter(model.name),
                url: repairModelPageBuilder(
                  brand.name ?? '',
                  model.slugFull ?? '',
                  getProductByCode(ProductTypeEnum.SMA)?.urlCode ?? ''
                ),
              })),
          }
          return item
        }),
        // others
        {
          label: 'Réparation Autres',
          url: reparationsLinks.all,
          type: 'brands',
          other: {
            label: 'Autres marques',
            url: reparationsLinks.other,
          },
          items: secondaryBrands.map((brand) => ({
            label: capitalizeFirstLetterOfWords(brand.name ?? ''),
            url: repairOtherBrandPageBuilder(
              getProductByCode(ProductTypeEnum.SMA)?.urlCode ?? '',
              brand.name ?? ''
            ),
          })),
        },
        // removed tablettes consoles and laptop from menu
        /*{
          label: 'Réparation Tablettes',
          url: reparationsLinks.tablet,
          type: 'models',
          other: {
            label: 'Autres tablettes',
            url: reparationsLinks.tablet,
          },
          items: otherModels.map((_models) => ({
            label: _models.name ?? '',
            url: repairModelPageBuilder(
              _models.id.split('-')[1],
              _models.slugFull ?? '',
              getProductByCode(_models.id.split('-')[0] ?? '')?.urlCode ?? ''
            ),
          })),
          label2: 'Consoles et Ordinateurs',
          url2: reparationsLinks.productType,
        },*/
      ],
    }

    return [repairItem, ...menu]
  }, [saveMenu])
}

export default useMenu
